import styled from "styled-components";

const EEG = () => {
  return (
    <Wrapper>
      <div className="questions">
        <h3 className="question">What is Electroencephalogram?</h3>
        <p className="answer">
          Electroencephalogram or EEG also known as a brain wave test measures
          the electrical activity of the brain. It helps the healthcare provider
          to diagnose and monitor different brain conditions such as Alzheimer’s
          disease, dementia, encephalitis, encephalopathy, etc.
        </p>
      </div>
      <div className="questions">
        <h3 className="question">How does Electroencephalogram work?</h3>
        <p>
          In this test, small metal discs or sensors are attached to the scalp
          of an individual to record the electrical impulses generated by the
          brain. These impulses are amplified and converted to a graph that
          appears on a computer screen or can be printed on paper.
        </p>
      </div>
      <div className="questions">
        <h3 className="question">When is an EEG recommended?</h3>
        <p>
          A healthcare provider recommends an EEG test to detect epilepsy, a
          condition that causes repeated seizures, and to monitor blood flow in
          the brain during brain surgery. This test is also recommended to
          determine the cause of symptoms such as:
        </p>

        <ul>
          <li>Confusion</li>
          <li>Fainting </li>
          <li>Memory loss </li>
          <li>Seizures </li>
        </ul>
        <p>
          The activity of brain cells is recorded during EEG and the specific
          brain wave pattern is monitored. Additionally, any brief bursts of
          energy and responses to stimuli such as flashing lights are
          particularly noted.
        </p>
      </div>

      <div className="questions">
        <h3 className="question">What are the advantages of EEG?</h3>
        <ul>
          <li>It is a non-invasive procedure.</li>
          <li>
            It is an efficient way to assess how well the various parts of the
            brain are functioning.
          </li>
          <li>It provides highly precise and timely measurements.</li>
        </ul>
      </div>
      <div className="questions">
        <h3 className="question">What preparation is needed for EEG?</h3>
        <h5 className="include">
          To prepare for an EEG test, your healthcare provider may recommend you
          the following:
        </h5>

        <ul>
          <li>
            Wash your hair, or keep the hair clean and avoid applying oil or
            gels to the hair as it may cause difficulty for the metal discs to
            stick..
          </li>
          <li>
            Sleep less than usual, if you are supposed to sleep while on the EEG
            test.
          </li>
          <li>
            Avoid tea, coffee, and energy drinks for 8 hours before the test.
          </li>
          <li>
            Stop certain medications (sedatives, tranquilizers, sleeping pills,
            medications for epilepsy) that can affect the test before undergoing
            EEG, after consulting your healthcare provider.• Stop certain
            medications (sedatives, tranquilizers, sleeping pills, medications
            for epilepsy) that can affect the test before undergoing EEG, after
            consulting your healthcare provider.
          </li>
        </ul>
      </div>
      <div className="questions">
        <h3 className="question">How long does EEG take?</h3>
        <p>
          EEG can take approximately 45 minutes to 2 hours to completely examine
          the brain activity.
        </p>
      </div>
      <div className="questions">
        <h3 className="question">What are the risks of EEG?</h3>
        <h5 className="include">
          EEG is mostly a safe procedure. Sometimes, it may cause risks such as
        </h5>
        <ul>
          <li>Tiredness.</li>
          <li>Lightheadedness</li>
          <li>Tingling sensation in lips and fingers</li>
          <li>Mild rash where the electrodes were placed </li>
          <li>Rarely, seizures may also be triggered during the procedure </li>
        </ul>
      </div>
    </Wrapper>
  );
};

export default EEG;
const Wrapper = styled.section``;
